/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Image, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"ATELIÉR"}>
        <Column className="--menu pb--08 pt--08" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box fs--22" content={"<a href=\"https://michaelasynacovafotografie.cz\">Michaela Synáčová Fotografie </a>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

              </MenuWrapBox>

              <MenuWrapBox style={{"backgroundColor":"rgba(255,255,255,1)"}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz"} use={"url"} href={"https://michaelasynacovafotografie.cz"} target={""} content={"<span style=\"font-weight: 400; color: rgb(0, 0, 0);\">home</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/foceni"} target={""} content={"<span style=\"caret-color: rgb(2, 0, 0); color: rgb(2, 0, 0); font-weight: 400;\">CENÍKY</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/info/"} use={"url"} href={"https://michaelasynacovafotografie.cz/info/"} target={""} content={"<span style=\"font-weight: 400; color: rgb(4, 0, 0);\">INFO</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/kontakt/"} use={"url"} href={"https://michaelasynacovafotografie.cz/kontakt/"} target={""} content={"<div><span style=\"font-weight: normal; color: rgb(0, 0, 0);\">KONTAKT</span><br></div>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center pb--80 pt--80" name={"zrj6nbcw1o"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"ATELIÉR<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6sfnofen9ud"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"5.května 653, Blovice&nbsp;<br>"}>
              </Title>

              <Button className="btn-box" url={"https://mapy.cz/s/kogepabute"} href={"https://mapy.cz/s/kogepabute"} content={"KDE PARKOVAT?<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l13"} name={"la3t1knmsnn"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13101/60a9fec1385444308251bb02fe4400b7_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} srcSet={"https://cdn.swbpg.com/t/13101/60a9fec1385444308251bb02fe4400b7_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13101/60a9fec1385444308251bb02fe4400b7_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13101/60a9fec1385444308251bb02fe4400b7_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={false} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--20 subtitle-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">Michaela Synáčová Fotografie<br></span><br>"}>
              </Subtitle>

              <Title className="title-box fs--26 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">NEWBORN A RODINNÁ FOTOGRAFKA</span>"}>
              </Title>

              <Text className="text-box fs--14 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">5.května 653, Blovice</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">+420 739 449 891</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert mt--0" content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">michaela.synacova.fotografie@gmail.com</span>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn1 btn-box--sbtn2 fs--16" href={"https://www.facebook.com/michaelasynacova.fotografie"} content={"<span style=\"color: rgb(0, 0, 0);\">facebook</span>"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 fs--16" href={"https://www.instagram.com/michaela.synacova.fotografie/"} content={"<span style=\"color: rgb(0, 0, 0);\">instagram</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}